import {
  SwapSDK as ChainflipSDK,
  getChainflipId,
  type Chain as ChainflipChain,
  type Asset as ChainflipAsset,
  type SwapStatusResponse as ChainflipSwapStatusResponse,
  type DepositAddressRequest as ChainflipDepositAddressRequest,
  type QuoteRequest as ChainflipQuoteRequest,
  type ChainflipNetwork,
  type QuoteResponse,
} from '@chainflip/sdk/swap';
import { isAxiosError } from 'axios';
import { ethers } from 'ethers';
import { type TransactionResponse } from 'ethers/providers';
import { erc20Abi } from 'viem';
import { chainById, type ChainId } from '@/shared/assets/chains';
import ChainflipMonochromaticLogo from '@/shared/assets/svg/monochromatic-logos/cf.svg';
import { FlipLogo } from '@/shared/assets/token-logos';
import { isChainflipTokenOrChain, type ChainflipToken } from '@/shared/assets/tokens';
import { NATIVE_TOKEN_ADDRESS } from '@/shared/assets/tokens/constants';
import { type WalletClient, clientToSigner } from '@/shared/hooks/useEthersSigner';
import {
  TokenAmount,
  isTruthy,
  isHash,
  isNullish,
  abbreviate,
  chainflipChainMap,
  chainflipAssetMap,
  getChainflipAsset,
  getChainflipNetwork,
  toUpperCase,
  entries,
} from '@/shared/utils';
import { assetConstants, readAssetValue } from '@/shared/utils/chainflip';
import { convertFokMinPriceToReceivedAmount } from '@/shared/utils/convertFokMinPriceToReceivedAmount';
import { type BaseIntegration, getDeterministicRouteId } from './manager';
import {
  loadRouteFromLocalStorage,
  storeDepositChannelIdInLocalStorage,
  storeRouteInLocalStorage,
} from './storage';
import { BOOST_FEE, BROKER_FEE, EGRESS_FEE, INGRESS_FEE, NETWORK_FEE } from '../utils/consts';
import {
  type ChainflipRouteResponse,
  type ChainflipStatusResponse,
  type EventLog,
  type RouteRequest,
  type RouteResponse,
  type RouteResponseStep,
  type SwapStatus,
} from '.';

type ChainflipApproveVaultParams = Parameters<ChainflipSDK['approveVault']>[0];
type SwapFee = QuoteResponse['quote']['includedFees'][number];

const feeTypeMap: Record<SwapFee['type'], string | undefined> = {
  BROKER: BROKER_FEE,
  NETWORK: NETWORK_FEE,
  BOOST: BOOST_FEE,
  INGRESS: INGRESS_FEE,
  EGRESS: EGRESS_FEE,
};

const depositChannelIdRegex = /^(?<issuedBlock>\d+)-(?<srcChain>[a-z]+)-(?<channelId>\d+)$/i;
const transactionHashRegex = /^0x[a-f\d]+$/i;

const chainflipStatusMap: Record<ChainflipSwapStatusResponse['state'], SwapStatus | undefined> = {
  AWAITING_DEPOSIT: 'waiting_for_src_tx',
  DEPOSIT_RECEIVED: 'waiting_for_dest_tx',
  SWAP_EXECUTED: 'waiting_for_dest_tx',
  EGRESS_SCHEDULED: 'waiting_for_dest_tx',
  BROADCAST_REQUESTED: 'waiting_for_dest_tx',
  BROADCASTED: 'completed',
  BROADCAST_ABORTED: 'failed',
  FAILED: 'failed',
  COMPLETE: 'completed',
} as const;

export const mapChainIdToChainflip = (chainId: ChainId): ChainflipChain | undefined => {
  const chain = chainById(chainId);

  return isChainflipTokenOrChain(chain) ? chain.chainflipId : undefined;
};

const mapChainflipChain = (chainflipChain: ChainflipChain): ChainId => {
  if (chainflipChain in chainflipChainMap) {
    return chainflipChainMap[chainflipChain as keyof typeof chainflipChainMap].id;
  }

  throw new Error(`unexpected chain from chainflip sdk: ${chainflipChain}`);
};

const mapChainflipAsset = (
  chainflipChain: ChainflipChain,
  chainflipAsset: ChainflipAsset,
): ChainflipToken => {
  const chainflipId = getChainflipId({ asset: chainflipAsset, chain: chainflipChain });

  if (chainflipId in chainflipAssetMap) {
    return chainflipAssetMap[chainflipId as keyof typeof chainflipAssetMap];
  }

  throw new Error(`unexpected asset from chainflip sdk: ${chainflipId}`);
};

const mapChainflipStatus = (chainflipStatus: ChainflipSwapStatusResponse['state']): SwapStatus =>
  chainflipStatusMap[chainflipStatus] ?? 'unknown';

export const isBoostedChannel = (sdkStatus: ChainflipSwapStatusResponse | undefined) =>
  Boolean(
    sdkStatus &&
      'depositChannelMaxBoostFeeBps' in sdkStatus &&
      sdkStatus.depositChannelMaxBoostFeeBps &&
      sdkStatus.depositChannelMaxBoostFeeBps > 0,
  );

export const isBoostSkipped = (
  sdkStatus: ChainflipSwapStatusResponse | undefined,
): sdkStatus is ChainflipSwapStatusResponse & {
  boostSkippedAt: number;
  boostSkippedBlockIndex: string;
} => Boolean(sdkStatus && 'boostSkippedAt' in sdkStatus && sdkStatus.boostSkippedAt);

export const isSwapBoosted = (
  sdkStatus: ChainflipSwapStatusResponse | undefined,
): sdkStatus is ChainflipSwapStatusResponse & {
  depositBoostedAt: number;
  depositBoostedBlockIndex: string;
} => Boolean(sdkStatus && 'depositBoostedAt' in sdkStatus && sdkStatus.depositBoostedAt);

export const isBoostPending = (sdkStatus: ChainflipSwapStatusResponse | undefined) =>
  isBoostedChannel(sdkStatus) && !isBoostSkipped(sdkStatus) && !isSwapBoosted(sdkStatus);

const useBoostQuote = (sdkStatus: ChainflipSwapStatusResponse | undefined) =>
  isBoostedChannel(sdkStatus) && !isBoostSkipped(sdkStatus);

export const calculateMinPrice = (
  quote: ChainflipRouteResponse['integrationData'],
  slippageTolerancePercent: number,
) => {
  if (!quote?.quotedPrice) {
    throw new Error(`Missing quotedPrice when calculating minimum price`);
  }

  return String(quote!.quotedPrice! * (1 - slippageTolerancePercent / 100));
};

export const calculateMinReceived = (route: ChainflipRouteResponse, minPrice: string) =>
  convertFokMinPriceToReceivedAmount({
    srcAmount: route.srcAmount,
    ingressFee:
      route.platformFees.find((fee) => fee.name === INGRESS_FEE)?.amount ?? new TokenAmount(0),
    egressFee:
      route.platformFees.find((fee) => fee.name === EGRESS_FEE)?.amount ?? new TokenAmount(0),
    minPrice,
    destTokenDecimals: route.destToken.decimals,
  });

const Logo = () => ChainflipMonochromaticLogo({ style: { scale: '80%' } });

const buildEventLog = (
  sdkStatus: ChainflipSwapStatusResponse,
  route: RouteResponse,
  depositChannelId: string | undefined,
  depositTransactionConfirmations: number | undefined,
): EventLog[] => {
  const pastEvents: EventLog[] = [];
  const srcAmountWithSymbol = `${route.srcAmount.toPreciseFixedDisplay()} ${route.srcToken.symbol}`;
  const destAmountWithSymbol = `${route.destAmount.toPreciseFixedDisplay()} ${
    route.destToken.symbol
  }`;
  const abbreviatedDestAddress = abbreviate(sdkStatus.destAddress);

  if (depositChannelId) {
    pastEvents.push({
      message: 'Deposit channel created',
      status: 'success',
      linkTitle: 'View on Explorer',
      logo: Logo,
      link: `${process.env.NEXT_PUBLIC_EXPLORER_URL}/channels/${depositChannelId}`,
    });
  }

  if ('depositReceivedBlockIndex' in sdkStatus) {
    if (isBoostedChannel(sdkStatus)) {
      if (isSwapBoosted(sdkStatus)) {
        pastEvents.push({
          message: `${srcAmountWithSymbol} deposit boosted after 1 block confirmation`,
          status: 'success',
          logo: Logo,
          link: `${process.env.NEXT_PUBLIC_EXPLORER_URL}/events/${sdkStatus.depositBoostedBlockIndex}`,
        });
      } else if (isBoostSkipped(sdkStatus)) {
        pastEvents.push({
          message: `Boost attempt failed. Not enough available liquidity`,
          status: 'error',
          logo: Logo,
          link: undefined,
        });
      } else {
        pastEvents.push({
          message: `Checking available Boost liquidity`,
          status: 'processing',
          logo: Logo,
          link: undefined,
        });
      }
    }

    if (!isSwapBoosted(sdkStatus)) {
      pastEvents.push({
        message: `${srcAmountWithSymbol} deposited`,
        status: 'success',
        logo: Logo,
        link: `${process.env.NEXT_PUBLIC_EXPLORER_URL}/events/${sdkStatus.depositReceivedBlockIndex}`,
      });
    }

    pastEvents.push({
      message: `Swap scheduled`,
      status: 'success',
      linkTitle: 'View on Explorer',
      logo: Logo,
      link: `${process.env.NEXT_PUBLIC_EXPLORER_URL}/swaps/${sdkStatus.swapId}`,
    });
  } else {
    let message;
    if (!depositTransactionConfirmations) {
      message = 'Waiting to receive your funds';
    } else if (
      sdkStatus.srcChainRequiredBlockConfirmations &&
      depositTransactionConfirmations >= sdkStatus.srcChainRequiredBlockConfirmations
    ) {
      message = 'Waiting for witnessing to complete';
    } else {
      message = 'Waiting for block confirmations';
    }

    return [
      ...pastEvents,
      {
        message,
        status: 'processing',
        logo: Logo,
        link: undefined,
      },
    ];
  }

  if ('egressType' in sdkStatus && sdkStatus.egressType === 'SWAP') {
    if ('swapExecutedBlockIndex' in sdkStatus) {
      pastEvents.push({
        message: `Swapped ${srcAmountWithSymbol} for ${destAmountWithSymbol} successfully`,
        status: 'success',
        logo: Logo,
        link: `${process.env.NEXT_PUBLIC_EXPLORER_URL}/events/${sdkStatus.swapExecutedBlockIndex}`,
      });
    }

    if ('egressScheduledBlockIndex' in sdkStatus) {
      pastEvents.push({
        message: `Requesting to send ${destAmountWithSymbol} to the destination address ${abbreviatedDestAddress}`,
        status: 'success',
        logo: Logo,
        link: `${process.env.NEXT_PUBLIC_EXPLORER_URL}/events/${sdkStatus.egressScheduledBlockIndex}`,
      });
    }

    if (sdkStatus.state === 'BROADCASTED' || 'broadcastSucceededBlockIndex' in sdkStatus) {
      pastEvents.push({
        message: `${destAmountWithSymbol} sent to address ${abbreviatedDestAddress}`,
        status: 'success',
        logo: Logo,
        link:
          'broadcastSucceededBlockIndex' in sdkStatus
            ? `${process.env.NEXT_PUBLIC_EXPLORER_URL}/events/${sdkStatus.broadcastSucceededBlockIndex}`
            : undefined,
      });
    } else if ('broadcastRequestedBlockIndex' in sdkStatus) {
      pastEvents.push({
        message: `Sending ${destAmountWithSymbol} to the destination address ${abbreviatedDestAddress}`,
        status: 'processing',
        logo: Logo,
        link: undefined,
      });
    }
  } else if (
    'egressType' in sdkStatus &&
    sdkStatus.egressType === 'REFUND' &&
    sdkStatus.fillOrKillParams
  ) {
    const abbreviatedRefundAddress = abbreviate(sdkStatus.fillOrKillParams.refundAddress);
    const refundAmountWithSymbol = `${new TokenAmount(
      sdkStatus.egressAmount,
      route.srcToken.decimals,
    ).toPreciseFixedDisplay()} ${route.srcToken.symbol}`;

    pastEvents.push({
      message: `Slippage tolerance exceeded`,
      status: 'error',
      logo: Logo,
      link: `${process.env.NEXT_PUBLIC_EXPLORER_URL}/events/${sdkStatus.egressScheduledBlockIndex}`,
    });

    if (sdkStatus.state === 'BROADCASTED' || 'broadcastSucceededBlockIndex' in sdkStatus) {
      pastEvents.push({
        message: `${refundAmountWithSymbol} sent to address ${abbreviatedRefundAddress}`,
        status: 'success',
        logo: Logo,
        link:
          'broadcastSucceededBlockIndex' in sdkStatus
            ? `${process.env.NEXT_PUBLIC_EXPLORER_URL}/events/${sdkStatus.broadcastSucceededBlockIndex}`
            : undefined,
      });
    } else if ('broadcastRequestedBlockIndex' in sdkStatus) {
      pastEvents.push({
        message: `Sending ${refundAmountWithSymbol} to the address ${abbreviatedRefundAddress}`,
        status: 'processing',
        logo: Logo,
        link: undefined,
      });
    }
  } else {
    pastEvents.push({
      message: `Preparing to swap ${srcAmountWithSymbol} to ${route.destToken.symbol}`,
      status: 'processing',
      logo: Logo,
      link: undefined,
    });
  }

  if ('broadcastAbortedBlockIndex' in sdkStatus || sdkStatus.state === 'FAILED') {
    const index =
      'broadcastAbortedBlockIndex' in sdkStatus
        ? sdkStatus.broadcastAbortedBlockIndex
        : sdkStatus.egressIgnoredBlockIndex;
    pastEvents.push({
      message: `Swap failed because of an unexpected reason`,
      status: 'error',
      logo: Logo,
      link: `${process.env.NEXT_PUBLIC_EXPLORER_URL}/events/${index}`,
    });
  }

  return pastEvents;
};

const buildRouteObject = (
  swapData: ChainflipQuoteRequest,
  quote: {
    intermediateAmount?: string;
    egressAmount: string;
    includedFees: SwapFee[];
    estimatedDurationSeconds: number;
    estimatedPrice?: string;
  },
  boostData: { isBoosted: boolean; defaultDurationSeconds: number },
): ChainflipRouteResponse => {
  const srcToken = mapChainflipAsset(swapData.srcChain, swapData.srcAsset);
  const srcAmount = new TokenAmount(swapData.amount, srcToken.decimals);
  const destToken = mapChainflipAsset(swapData.destChain, swapData.destAsset);
  const destAmount = new TokenAmount(quote.egressAmount, destToken.decimals);

  const intermediateUsdcAmount = quote.intermediateAmount
    ? new TokenAmount(quote.intermediateAmount, chainflipAssetMap.Usdc.decimals)
    : undefined;

  const steps: RouteResponseStep<ChainflipToken>[] = intermediateUsdcAmount
    ? [
        {
          protocolName: 'Chainflip',
          protocolLink: undefined,
          srcToken,
          srcAmount,
          destAmount: intermediateUsdcAmount,
          destToken: chainflipAssetMap.Usdc,
        },
        {
          protocolName: 'Chainflip',
          protocolLink: undefined,
          srcToken: chainflipAssetMap.Usdc,
          srcAmount: intermediateUsdcAmount,
          destToken,
          destAmount,
        },
      ]
    : [
        {
          protocolName: 'Chainflip',
          protocolLink: undefined,
          srcToken,
          srcAmount,
          destToken,
          destAmount,
        },
      ];

  const platformFees = entries(feeTypeMap)
    .filter(([, name]) => name)
    .flatMap(([type, name]) =>
      quote.includedFees
        .filter((fee) => fee.type === type)
        .map((fee) => {
          const token = mapChainflipAsset(fee.chain, fee.asset);

          return {
            name: name as string,
            token,
            amount: new TokenAmount(fee.amount, token.decimals),
          };
        }),
    );

  const routeResponse = {
    integration: 'chainflip' as const,
    integrationData: {
      ...swapData,
      ...boostData,
      quotedPrice: quote.estimatedPrice ? Number(quote.estimatedPrice) : undefined,
    },
    srcToken,
    srcAmount,
    destToken,
    destAmount,
    steps,
    gasFees: [],
    platformFees,
    durationSeconds: quote.estimatedDurationSeconds,
  };

  return { ...routeResponse, id: getDeterministicRouteId(routeResponse) };
};

const transactionHashKey = (swapId: string) => `chainflip-deposit-transaction-${swapId}`;

const storeTransactionHashInLocalStorage = (swapId: string, txHash: string) => {
  localStorage.setItem(transactionHashKey(swapId), txHash);
};
const loadTransactionHashFromLocalStorage = (swapId: string): string | undefined =>
  localStorage.getItem(transactionHashKey(swapId)) ?? undefined;

const addEventListener = (handler: (ev: PromiseRejectionEvent) => void) => {
  window.addEventListener('unhandledrejection', handler);

  return () => {
    window.removeEventListener('unhandledrejection', handler);
  };
};

const deferredPromise = <T>() => {
  let resolve: (value: T | PromiseLike<T>) => void;
  let reject: (reason?: unknown) => void;

  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return { promise, resolve: resolve!, reject: reject! };
};

const getHashFromMetamaskError = () => {
  const { promise, resolve } = deferredPromise<string>();

  const remove = addEventListener((ev) => {
    if (ev.reason?.code === 'INVALID_ARGUMENT') {
      const { hash } = ev.reason.value;
      if (typeof hash === 'string') {
        resolve(hash);
      }
    }
  });

  return { promise, remove };
};

export class ChainflipIntegration implements BaseIntegration {
  sdk = new ChainflipSDK({
    network: getChainflipNetwork() as ChainflipNetwork,
    backendUrl: process.env.NEXT_PUBLIC_CHAINFLIP_BACKEND_URL,
    rpcUrl: process.env.NEXT_PUBLIC_STATECHAIN_NODE_URI,
  });

  readonly name = 'Chainflip';

  readonly logo = FlipLogo;

  getChains = async () => {
    const sdkChains = await this.sdk.getChains();

    return sdkChains.map((chain) => chainById(mapChainflipChain(chain.chain))).filter(isTruthy);
  };

  getDestinationChains = async (srcChainId: ChainId) => {
    const chainflipChain = mapChainIdToChainflip(srcChainId);
    if (!chainflipChain) return [];

    const sdkChains = (await this.sdk.getChains(chainflipChain)) ?? [];

    return sdkChains.map((chain) => chainById(mapChainflipChain(chain.chain))).filter(isTruthy);
  };

  getRoutes = async (routeParams: RouteRequest) => {
    const srcChain = mapChainIdToChainflip(routeParams.srcChainId);
    const srcChainflipAsset = getChainflipAsset(
      routeParams.srcChainId,
      routeParams.srcTokenAddress,
    );
    const srcAsset = srcChainflipAsset ? assetConstants[srcChainflipAsset].rpcAsset : undefined;
    const destChain = mapChainIdToChainflip(routeParams.destChainId);
    const destChainflipAsset = getChainflipAsset(
      routeParams.destChainId,
      routeParams.destTokenAddress,
    );
    const destAsset = destChainflipAsset ? assetConstants[destChainflipAsset].rpcAsset : undefined;

    const { minimumAmount, maximumAmount } = await this.getSwapLimits(
      routeParams.srcChainId,
      routeParams.srcTokenAddress,
    );
    const { amount } = routeParams;

    if (
      !srcChain ||
      !srcAsset ||
      !destChain ||
      !destAsset ||
      amount < minimumAmount ||
      (maximumAmount && amount > maximumAmount)
    ) {
      return [];
    }

    const sdkQuote = await this.sdk.getQuote({
      srcChain,
      destChain,
      srcAsset: toUpperCase(srcAsset),
      destAsset: toUpperCase(destAsset),
      amount: amount.toString(),
    });
    const { estimatedDurationSeconds: defaultDurationSeconds } = sdkQuote.quote;

    return [
      buildRouteObject(sdkQuote, sdkQuote.quote, { isBoosted: false, defaultDurationSeconds }),
      sdkQuote.quote.boostQuote &&
        buildRouteObject(sdkQuote, sdkQuote.quote.boostQuote, {
          isBoosted: true,
          defaultDurationSeconds,
        }),
    ].filter(isTruthy);
  };

  getTokens = async (chainId: ChainId) => {
    const chainflipChain = mapChainIdToChainflip(chainId);
    if (!chainflipChain) return [];

    const sdkAssets = await this.sdk.getAssets(chainflipChain);

    return sdkAssets.map((asset) => mapChainflipAsset(asset.chain, asset.asset));
  };

  async getSwapLimits(
    chainId: ChainId,
    tokenAddress: string,
  ): Promise<{ maximumAmount: bigint | null; minimumAmount: bigint }> {
    const limits = await this.sdk.getSwapLimits();

    const chainflipAsset = getChainflipAsset(chainId, tokenAddress);
    if (!chainflipAsset) {
      return { minimumAmount: 0n, maximumAmount: null };
    }

    return {
      minimumAmount: readAssetValue(limits.minimumSwapAmounts, chainflipAsset),
      maximumAmount: readAssetValue(limits.maximumSwapAmounts, chainflipAsset),
    };
  }

  getStatus = async (swapId: string): Promise<ChainflipStatusResponse | undefined> => {
    const storedRoute = loadRouteFromLocalStorage('chainflip', swapId);
    const depositChannelId = depositChannelIdRegex.test(swapId)
      ? swapId
      : storedRoute?.depositChannelId;
    const localTransactionHash = transactionHashRegex.test(swapId)
      ? swapId
      : loadTransactionHashFromLocalStorage(swapId);

    const shareableId = depositChannelId ?? localTransactionHash;

    let sdkStatus: ChainflipSwapStatusResponse | undefined;
    if (shareableId) {
      try {
        sdkStatus = await this.sdk.getStatus({ id: shareableId });
        // remove null and undefined properties from object to allow for "'property' in status" checks
        // TODO: make sure that the object returned by the sdk matches the exported types
        sdkStatus = Object.fromEntries(
          Object.entries(sdkStatus).filter(([, value]) => !isNullish(value)),
        ) as ChainflipSwapStatusResponse;
      } catch (e) {
        // ignore 404: sdk will return status of transaction hash only after deposit is witnessed
        if (!(isAxiosError(e) && e.response?.status === 404)) throw e;
      }
    }

    let swapParams: ChainflipDepositAddressRequest & { useBoostQuote: boolean };

    if (sdkStatus && sdkStatus.state !== 'FAILED') {
      const srcAmount =
        'depositAmount' in sdkStatus && sdkStatus.depositAmount
          ? sdkStatus.depositAmount
          : sdkStatus.expectedDepositAmount ?? '0';

      swapParams = {
        ...sdkStatus,
        amount: srcAmount,
        useBoostQuote: useBoostQuote(sdkStatus),
      };
    } else if (sdkStatus?.state === 'FAILED') {
      // for smart contract failures, we don't know the destination
      if (!sdkStatus.destChain || !sdkStatus.destAsset) return undefined;

      swapParams = {
        ...sdkStatus,
        amount: sdkStatus.depositAmount,
        useBoostQuote: useBoostQuote(sdkStatus),
      };
    } else if (storedRoute && storedRoute.integration === 'chainflip') {
      swapParams = {
        ...storedRoute.integrationData,
        destAddress: storedRoute.destAddress,
        useBoostQuote: storedRoute.integrationData.isBoosted,
        amount: storedRoute.srcAmount.toString(),
      };
    } else {
      return undefined;
    }

    // get quote data from status if the amounts are locked in, otherwise fetch fresh quote
    let quoteData;
    let defaultDurationSeconds = 0;
    if (sdkStatus && ('egressAmount' in sdkStatus || sdkStatus.state === 'FAILED')) {
      quoteData = {
        egressAmount: 'egressAmount' in sdkStatus ? sdkStatus.egressAmount : '0',
        intermediateAmount:
          'intermediateAmount' in sdkStatus ? sdkStatus.intermediateAmount : undefined,
        includedFees: sdkStatus && 'feesPaid' in sdkStatus ? sdkStatus.feesPaid : [],
        estimatedDurationSeconds: 0,
      };
    } else {
      const sdkQuote = await this.sdk.getQuote(swapParams);
      swapParams.useBoostQuote = swapParams.useBoostQuote && Boolean(sdkQuote.quote.boostQuote); // set to false if no boost quote is available
      quoteData = swapParams.useBoostQuote ? sdkQuote.quote.boostQuote! : sdkQuote.quote;
      defaultDurationSeconds = sdkQuote.quote.estimatedDurationSeconds;
    }

    const route = buildRouteObject(swapParams, quoteData, {
      isBoosted: swapParams.useBoostQuote,
      defaultDurationSeconds,
    });

    const duration =
      sdkStatus && 'egressScheduledAt' in sdkStatus && sdkStatus.depositChannelCreatedAt
        ? sdkStatus.egressScheduledAt - sdkStatus.depositChannelCreatedAt
        : undefined;

    let srcTransactionHash;
    if (sdkStatus && 'depositTransactionHash' in sdkStatus) {
      srcTransactionHash = sdkStatus.depositTransactionHash;
    } else if (
      ['Ethereum', 'Arbitrum'].includes(swapParams.srcChain) &&
      isHash(localTransactionHash)
    ) {
      srcTransactionHash = localTransactionHash;
    }

    let srcConfirmationCount;
    if (sdkStatus && 'depositTransactionConfirmations' in sdkStatus) {
      srcConfirmationCount = sdkStatus.depositTransactionConfirmations;
    }

    let status = sdkStatus ? mapChainflipStatus(sdkStatus.state) : 'waiting_for_src_tx';
    if (
      status === 'waiting_for_src_tx' &&
      (srcTransactionHash || srcConfirmationCount !== undefined)
    ) {
      status = 'waiting_for_src_tx_confirmation'; // show receiving after tx was submitted via connected wallet
    }

    return {
      id: swapId,
      shareableId,
      integration: 'chainflip' as const,
      integrationData: sdkStatus,
      status,
      route,
      swapExplorerUrl: undefined,
      depositAddress: sdkStatus?.depositAddress,
      srcTransactionHash,
      srcConfirmationCount,
      destAddress: swapParams.destAddress,
      destTransactionHash: undefined,
      duration,
      eventLogs: sdkStatus
        ? buildEventLog(sdkStatus, route, depositChannelId, srcConfirmationCount).reverse()
        : [],
    };
  };

  createDepositChannel = async (
    swapId: string,
    options: {
      srcAddress?: string;
      maxBoostFeeBps?: number;
      slippageParams?: { slippageTolerancePercent: number; swapDeadlineMinutes: number };
    },
  ) => {
    const preparedRoute = loadRouteFromLocalStorage('chainflip', swapId);
    if (!preparedRoute || preparedRoute.integration !== 'chainflip') {
      throw new Error(`Invalid route when opening deposit channel`);
    }

    const { slippageParams, ...remainingOptions } = options;

    let fillOrKillParams;
    if (slippageParams) {
      if (!preparedRoute.refundAddress) {
        throw new Error(`Missing refund address when opening deposit channel`);
      }

      if (!preparedRoute.integrationData?.quotedPrice) {
        throw new Error(`Missing quote price when opening deposit channel`);
      }

      fillOrKillParams = {
        retryDurationBlocks: slippageParams.swapDeadlineMinutes * 10,
        refundAddress: preparedRoute.refundAddress,
        minPrice: calculateMinPrice(
          preparedRoute.integrationData,
          slippageParams.slippageTolerancePercent,
        ),
      };
    }

    const response = await this.sdk.requestDepositAddress({
      destAddress: preparedRoute.destAddress,
      fillOrKillParams,
      ...preparedRoute.integrationData,
      ...remainingOptions,
    });
    storeDepositChannelIdInLocalStorage('chainflip', swapId, response.depositChannelId);

    return response;
  };

  executeSwap = async (swapId: string, walletClient: WalletClient) => {
    const status = await this.getStatus(swapId);
    if (!status) {
      throw new Error(`Status of swap "${swapId}" not found`);
    }
    if (status.route.integration !== 'chainflip') {
      throw new Error(`Unexpected route when executing swap "${swapId}"`);
    }
    if (!status.destAddress) {
      throw new Error(`Missing dest address when executing swap "${swapId}"`);
    }

    let transactionHash;
    const signer = clientToSigner(walletClient);

    if (status.depositAddress) {
      const { promise, remove } = getHashFromMetamaskError();

      const txPromise: Promise<TransactionResponse> =
        status.route.srcToken.address === NATIVE_TOKEN_ADDRESS
          ? signer.sendTransaction({
              value: status.route.srcAmount.toString(),
              to: status.depositAddress,
            })
          : new ethers.Contract(status.route.srcToken.address, erc20Abi, signer).transfer(
              status.depositAddress,
              status.route.srcAmount.toString(),
            );

      const submittedTransaction = await Promise.race([
        txPromise,
        promise.then((hash) => ({ hash })),
      ]).finally(remove);

      transactionHash = submittedTransaction.hash;
    } else {
      const sdkWithSigner = new ChainflipSDK({
        signer,
        backendUrl: process.env.NEXT_PUBLIC_CHAINFLIP_BACKEND_URL,
        network: getChainflipNetwork() as ChainflipNetwork,
        rpcUrl: process.env.NEXT_PUBLIC_STATECHAIN_NODE_URI,
      });
      if (status.route.srcToken.address !== NATIVE_TOKEN_ADDRESS) {
        const { promise, remove } = getHashFromMetamaskError();

        await Promise.race([
          sdkWithSigner.approveVault(
            {
              ...status.route.integrationData,
              amount: status.route.integrationData.amount,
            } as ChainflipApproveVaultParams,
            { wait: 1 },
          ),
          promise.then(async (hash) => {
            await signer.provider.waitForTransaction(hash, 1);
          }),
        ]).finally(remove);
      }

      const { promise, remove } = getHashFromMetamaskError();

      transactionHash = await Promise.race([
        sdkWithSigner.executeSwap({
          ...status.route.integrationData,
          destAddress: status.destAddress,
        }),
        promise,
      ]).finally(remove);
    }

    storeTransactionHashInLocalStorage(status.shareableId ?? status.id, transactionHash);
    // store route data as sdk will return status for transaction hash only after deposit was witnessed
    storeRouteInLocalStorage('chainflip', transactionHash, {
      ...status.route,
      destAddress: status.destAddress,
    });

    return {
      integration: 'chainflip' as const,
      integrationData: transactionHash,
      error: undefined,
    };
  };
}
